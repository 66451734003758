// tslint:disable
import * as EntityClasses from "@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g";
import * as Entities from "@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g";
import { jsonLightReadStringPropertyValue } from "@msdyn365-commerce/retail-proxy/dist/externals";

/**
 * Cases entity interface.
 */
export interface ICases {
  Id: number;
  ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * AllCustomerCasesResponse entity interface.
 */
export interface IAllCustomerCasesResponse {
  Success: boolean;
  Exception?: string;
  Data?: IAllCustomerCases;
}

/**
 * CreateCaseResponse entity interface.
 */
export interface ICreateCaseResponse {
  Success: boolean;
  Exception?: string;
  Data?: ICase;
}

/**
 * UpdateCaseResponse entity interface.
 */
export interface IUpdateCaseResponse {
  Success: boolean;
  Exception?: string;
  Data?: ICase;
}

/**
 * GetCaseNotesResponse entity interface.
 */
export interface IGetCaseNotesResponse {
  Success: boolean;
  Exception?: string;
  Data?: ICaseNotes;
}

/**
 * GetSpecificCaseResponse entity interface.
 */
export interface IGetSpecificCaseResponse {
  Success: boolean;
  Exception?: string;
  Data?: ICaseData;
}

/**
 * GetLatestNoteResponse entity interface.
 */
export interface IGetLatestNoteResponse {
  Success: boolean;
  Exception?: string;
  Data?: ILatestNotes;
}

/**
 * AllCustomerCases entity interface.
 */
export interface IAllCustomerCases {
  OdataContext?: string;
  Value?: IValue[];
}

/**
 * Value entity interface.
 */
export interface IValue {
  ODataEtag?: string;
  TicketNumber?: string;
  CreatedOn: Date;
  StatusCode: number;
  Br_ClaimCity?: string;
  New_NotifyMePerEmailWhenClaimIsUpdated?: string;
  New_ConsumerName?: string;
  Description?: string;
  New_ShipmentTrackingId?: string;
  New_InvoiceNumber?: string;
  IncidentId?: string;
  SubjectId?: ISubjectid;
  Customerid_account?: ICustomerid_Account;
  PrimaryContactId?: IPrimarycontactid;
  Incident_Annotation?: IIncidentAnnotation[];
}

/**
 * Subjectid entity interface.
 */
export interface ISubjectid {
  Title?: string;
  SubjectId?: string;
}

/**
 * Customerid_Account entity interface.
 */
export interface ICustomerid_Account {
  Accountnumber?: string;
  Accountid?: string;
}

/**
 * Primarycontactid entity interface.
 */
export interface IPrimarycontactid {
  Emailaddress1?: string;
  Contactid?: string;
}

/**
 * IncidentAnnotation entity interface.
 */
export interface IIncidentAnnotation {
  notetext?: string;
  createdon?: string;
  annotationid?: string;
  objecttypecode?: string;
  isdocument: boolean;
}

/**
 * Case entity interface.
 */
export interface ICase {
  incidentid?: string;
  ticketnumber?: string;
}

/**
 * CaseNotes entity interface.
 */
export interface ICaseNotes {
  ODataContext?: string;
  Value?: ICaseNote[];
}

/**
 * CaseNote entity interface.
 */
export interface ICaseNote {
  ODataETag?: string;
  NoteText?: string;
  FileName?: string;
  IsDocument: boolean;
  DocumentBody?: string;
  CreatedOn?: string;
  AnnotationId?: string;
  CreatedBy?: ICreatedBy;
}

/**
 * CreatedBy entity interface.
 */
export interface ICreatedBy {
  FullName?: string;
  SystemUserId?: string;
  OwnerId?: string;
}

/**
 * CaseData entity interface.
 */
export interface ICaseData {
  ODataContext?: string;
  Value?: ICustomerCase[];
}

/**
 * CustomerCase entity interface.
 */
export interface ICustomerCase {
  ODataETag?: string;
  TicketNumber?: string;
  CreatedOn?: string;
  StatusCode?: string;
  EmailAddress?: string;
  Br_DeliveryAddress?: string;
  Br_Country?: string;
  Br_ClaimCity?: string;
  Br_ClaimPostCode?: string;
  Br_ClaimStreet?: string;
  New_NotifyMePerEmailWhenClaimIsUpdated?: string;
  New_ConsumerName?: string;
  New_ConsumerContactDetails?: string;
  New_CustomerConfirmedThePrivacyPolicy?: string;
  Description?: string;
  New_OrderNumber?: string;
  New_OrderDate?: string;
  New_SalesOrderLineNumber?: string;
  Br_DateOfPurchase?: string;
  New_SerialNumber?: string;
  Br_HasThisProductBeenInAnAccident?: string;
  Br_TrackingNumber?: string;
  New_ShipmentTrackingId?: string;
  New_LoadId?: string;
  New_DeliveryNoteId?: string;
  New_InvoiceNumber?: string;
  New_InvoiceDate?: string;
  New_InvoiceLineNumber?: string;
  IncidentId?: string;
  SubjectId?: ISubjectid;
  Customerid_account?: ICustomerid_Account;
  PrimaryContactId?: IPrimarycontactid;
  Productid?: IProductid;
}

/**
 * Productid entity interface.
 */
export interface IProductid {
  Productnumber?: string;
  ProductId?: string;
}

/**
 * LatestNotes entity interface.
 */
export interface ILatestNotes {
  ODataContext?: string;
  Value?: ICaseNote[];
}

/**
 * CaseWithAttachment entity interface.
 */
export interface ICaseWithAttachment {
  StatusCodes: number;
  SubjectIdOdataBind?: string;
  CustomerIdAccountOdataBind?: string;
  PrimaryContactIdOdataBind?: string;
  EmailAddress?: string;
  Br_DeliveryAddress?: string;
  Br_ClaimCity?: string;
  Br_ClaimPostCode?: string;
  Br_ClaimStreet?: string;
  Br_Country?: string;
  New_NotifyMePerEmailWhenClaimIsUpdated: boolean;
  New_ConsumerName?: string;
  New_ConsumerContactDetails?: string;
  New_CustomerConfirmedThePrivacyPolicy: boolean;
  Description?: string;
  New_OrderNumber?: string;
  New_OrderDate?: string;
  New_SalesOrderLineNumber?: string;
  Br_DateOfPurchase?: string;
  ProductIdOdataBind?: string;
  New_SerialNumber?: string;
  New_ShipmentTrackingId?: string;
  New_LoadId?: string;
  New_DeliveryNoteId?: string;
  New_InvoiceNumber?: string;
  New_InvoiceDate?: string;
  New_InvoiceLineNumber?: string;
  Br_HasThisProductBeenInAnAccident: boolean;
  Br_TrackingNumber?: string;
  Br_ColorDescribtion?: string;
  New_D365itemnumber?: string;
  Incident_Annotation?: IAttachment[];
  CaseOriginCode?: string;
}

/**
 * UpdateCase entity interface.
 */
export interface IUpdateCase {
  NoteText?: string;
  FileName?: string;
  IsDocument: boolean;
  DocumentBody?: string;
  IncidentId?: string;
}

/**
 * Attachment entity interface.
 */
export interface IAttachment {
  NoteText?: string;
  FileName?: string;
  IsDocument: boolean;
  DocumentBody?: string;
}

/**
 * Cases entity class.
 */
export class CasesExtensionClass implements ICases {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Id: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ExtensionProperties: Entities.CommerceProperty[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Id = odataObject.Id;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ExtensionProperties = undefined;
    if (odataObject.ExtensionProperties) {
      this.ExtensionProperties = [];
      for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i]) {
          if (odataObject.ExtensionProperties[i]["@odata.type"]) {
            var className: string =
              odataObject.ExtensionProperties[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.ExtensionProperties[i] = new EntityClasses[className](
                odataObject.ExtensionProperties[i]
              );
            }
          } else {
            this.ExtensionProperties[i] =
              new EntityClasses.CommercePropertyClass(
                odataObject.ExtensionProperties[i]
              );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ExtensionProperties[i] = undefined;
        }
      }
    }
  }
}

/**
 * AllCustomerCasesResponse entity class.
 */
export class AllCustomerCasesResponseExtensionClass
  implements IAllCustomerCasesResponse
{
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Success: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Exception: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Data: IAllCustomerCases;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Success = odataObject.Success;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Exception = odataObject.Exception;

    if (odataObject.Data === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Data = undefined;
    } else if (odataObject.Data["@odata.type"] == null) {
      this.Data = new AllCustomerCasesExtensionClass(odataObject.Data);
    } else {
      var className: string = odataObject.Data["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Data = new EntityClasses[className](odataObject.Data);
      }
    }
  }
}

/**
 * CreateCaseResponse entity class.
 */
export class CreateCaseResponseExtensionClass implements ICreateCaseResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Success: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Exception: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Data: ICase;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Success = odataObject.Success;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Exception = odataObject.Exception;

    if (odataObject.Data === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Data = undefined;
    } else if (odataObject.Data["@odata.type"] == null) {
      this.Data = new CaseExtensionClass(odataObject.Data);
    } else {
      var className: string = odataObject.Data["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Data = new EntityClasses[className](odataObject.Data);
      }
    }
  }
}

/**
 * UpdateCaseResponse entity class.
 */
export class UpdateCaseResponseExtensionClass implements IUpdateCaseResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Success: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Exception: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Data: ICase;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Success = odataObject.Success;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Exception = odataObject.Exception;

    if (odataObject.Data === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Data = undefined;
    } else if (odataObject.Data["@odata.type"] == null) {
      this.Data = new CaseExtensionClass(odataObject.Data);
    } else {
      var className: string = odataObject.Data["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Data = new EntityClasses[className](odataObject.Data);
      }
    }
  }
}

/**
 * GetCaseNotesResponse entity class.
 */
export class GetCaseNotesResponseExtensionClass
  implements IGetCaseNotesResponse
{
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Success: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Exception: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Data: ICaseNotes;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Success = odataObject.Success;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Exception = odataObject.Exception;

    if (odataObject.Data === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Data = undefined;
    } else if (odataObject.Data["@odata.type"] == null) {
      this.Data = new CaseNotesExtensionClass(odataObject.Data);
    } else {
      var className: string = odataObject.Data["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Data = new EntityClasses[className](odataObject.Data);
      }
    }
  }
}

/**
 * GetSpecificCaseResponse entity class.
 */
export class GetSpecificCaseResponseExtensionClass
  implements IGetSpecificCaseResponse
{
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Success: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Exception: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Data: ICaseData;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Success = odataObject.Success;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Exception = odataObject.Exception;

    if (odataObject.Data === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Data = undefined;
    } else if (odataObject.Data["@odata.type"] == null) {
      this.Data = new CaseDataExtensionClass(odataObject.Data);
    } else {
      var className: string = odataObject.Data["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Data = new EntityClasses[className](odataObject.Data);
      }
    }
  }
}

/**
 * GetLatestNoteResponse entity class.
 */
export class GetLatestNoteResponseExtensionClass
  implements IGetLatestNoteResponse
{
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Success: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Exception: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Data: ILatestNotes;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Success = odataObject.Success;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Exception = odataObject.Exception;

    if (odataObject.Data === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Data = undefined;
    } else if (odataObject.Data["@odata.type"] == null) {
      this.Data = new LatestNotesExtensionClass(odataObject.Data);
    } else {
      var className: string = odataObject.Data["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Data = new EntityClasses[className](odataObject.Data);
      }
    }
  }
}

/**
 * AllCustomerCases entity class.
 */
export class AllCustomerCasesExtensionClass implements IAllCustomerCases {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public OdataContext: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Value: IValue[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.OdataContext = odataObject.OdataContext;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Value = undefined;
    if (odataObject.Value) {
      this.Value = [];
      for (var i = 0; i < odataObject.Value.length; i++) {
        if (odataObject.Value[i]) {
          if (odataObject.Value[i]["@odata.type"]) {
            var className: string = odataObject.Value[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Value[i] = new EntityClasses[className](
                odataObject.Value[i]
              );
            }
          } else {
            this.Value[i] = new ValueExtensionClass(odataObject.Value[i]);
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Value[i] = undefined;
        }
      }
    }
  }
}

/**
 * Value entity class.
 */
export class ValueExtensionClass implements IValue {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ODataEtag: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public TicketNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CreatedOn: Date;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public StatusCode: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_ClaimCity: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_NotifyMePerEmailWhenClaimIsUpdated: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_ConsumerName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Description: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_ShipmentTrackingId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_InvoiceNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IncidentId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SubjectId: ISubjectid;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Customerid_account: ICustomerid_Account;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public PrimaryContactId: IPrimarycontactid;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Incident_Annotation: IIncidentAnnotation[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ODataEtag = odataObject.ODataEtag;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.TicketNumber = odataObject.TicketNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CreatedOn =
      odataObject.CreatedOn instanceof Date
        ? odataObject.CreatedOn
        : odataObject.CreatedOn
        ? jsonLightReadStringPropertyValue(
            odataObject.CreatedOn,
            "Edm.DateTimeOffset",
            false
          )
        : undefined;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.StatusCode = odataObject.StatusCode;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_ClaimCity = odataObject.Br_ClaimCity;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_NotifyMePerEmailWhenClaimIsUpdated =
      odataObject.New_NotifyMePerEmailWhenClaimIsUpdated;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_ConsumerName = odataObject.New_ConsumerName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Description = odataObject.Description;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_ShipmentTrackingId = odataObject.New_ShipmentTrackingId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_InvoiceNumber = odataObject.New_InvoiceNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IncidentId = odataObject.IncidentId;

    if (odataObject.SubjectId === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.SubjectId = undefined;
    } else if (odataObject.SubjectId["@odata.type"] == null) {
      this.SubjectId = new SubjectidExtensionClass(odataObject.SubjectId);
    } else {
      var className: string = odataObject.SubjectId["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.SubjectId = new EntityClasses[className](odataObject.SubjectId);
      }
    }

    if (odataObject.Customerid_account === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Customerid_account = undefined;
    } else if (odataObject.Customerid_account["@odata.type"] == null) {
      this.Customerid_account = new Customerid_AccountExtensionClass(
        odataObject.Customerid_account
      );
    } else {
      var className: string = odataObject.Customerid_account["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Customerid_account = new EntityClasses[className](
          odataObject.Customerid_account
        );
      }
    }

    if (odataObject.PrimaryContactId === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.PrimaryContactId = undefined;
    } else if (odataObject.PrimaryContactId["@odata.type"] == null) {
      this.PrimaryContactId = new PrimarycontactidExtensionClass(
        odataObject.PrimaryContactId
      );
    } else {
      var className: string = odataObject.PrimaryContactId["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.PrimaryContactId = new EntityClasses[className](
          odataObject.PrimaryContactId
        );
      }
    }

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Incident_Annotation = undefined;
    if (odataObject.Incident_Annotation) {
      this.Incident_Annotation = [];
      for (var i = 0; i < odataObject.Incident_Annotation.length; i++) {
        if (odataObject.Incident_Annotation[i]) {
          if (odataObject.Incident_Annotation[i]["@odata.type"]) {
            var className: string =
              odataObject.Incident_Annotation[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Incident_Annotation[i] = new EntityClasses[className](
                odataObject.Incident_Annotation[i]
              );
            }
          } else {
            this.Incident_Annotation[i] = new IncidentAnnotationExtensionClass(
              odataObject.Incident_Annotation[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Incident_Annotation[i] = undefined;
        }
      }
    }
  }
}

/**
 * Subjectid entity class.
 */
export class SubjectidExtensionClass implements ISubjectid {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Title: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SubjectId: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Title = odataObject.Title;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SubjectId = odataObject.SubjectId;
  }
}

/**
 * Customerid_Account entity class.
 */
export class Customerid_AccountExtensionClass implements ICustomerid_Account {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Accountnumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Accountid: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Accountnumber = odataObject.Accountnumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Accountid = odataObject.Accountid;
  }
}

/**
 * Primarycontactid entity class.
 */
export class PrimarycontactidExtensionClass implements IPrimarycontactid {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Emailaddress1: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Contactid: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Emailaddress1 = odataObject.Emailaddress1;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Contactid = odataObject.Contactid;
  }
}

/**
 * IncidentAnnotation entity class.
 */
export class IncidentAnnotationExtensionClass implements IIncidentAnnotation {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public notetext: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public createdon: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public annotationid: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public objecttypecode: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public isdocument: boolean;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.notetext = odataObject.notetext;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.createdon = odataObject.createdon;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.annotationid = odataObject.annotationid;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.objecttypecode = odataObject.objecttypecode;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.isdocument = odataObject.isdocument;
  }
}

/**
 * Case entity class.
 */
export class CaseExtensionClass implements ICase {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public incidentid: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ticketnumber: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.incidentid = odataObject.incidentid;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ticketnumber = odataObject.ticketnumber;
  }
}

/**
 * CaseNotes entity class.
 */
export class CaseNotesExtensionClass implements ICaseNotes {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ODataContext: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Value: ICaseNote[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ODataContext = odataObject.ODataContext;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Value = undefined;
    if (odataObject.Value) {
      this.Value = [];
      for (var i = 0; i < odataObject.Value.length; i++) {
        if (odataObject.Value[i]) {
          if (odataObject.Value[i]["@odata.type"]) {
            var className: string = odataObject.Value[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Value[i] = new EntityClasses[className](
                odataObject.Value[i]
              );
            }
          } else {
            this.Value[i] = new CaseNoteExtensionClass(odataObject.Value[i]);
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Value[i] = undefined;
        }
      }
    }
  }
}

/**
 * CaseNote entity class.
 */
export class CaseNoteExtensionClass implements ICaseNote {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ODataETag: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public NoteText: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public FileName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsDocument: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public DocumentBody: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CreatedOn: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public AnnotationId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CreatedBy: ICreatedBy;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ODataETag = odataObject.ODataETag;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.NoteText = odataObject.NoteText;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.FileName = odataObject.FileName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsDocument = odataObject.IsDocument;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.DocumentBody = odataObject.DocumentBody;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CreatedOn = odataObject.CreatedOn;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.AnnotationId = odataObject.AnnotationId;

    if (odataObject.CreatedBy === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.CreatedBy = undefined;
    } else if (odataObject.CreatedBy["@odata.type"] == null) {
      this.CreatedBy = new CreatedByExtensionClass(odataObject.CreatedBy);
    } else {
      var className: string = odataObject.CreatedBy["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.CreatedBy = new EntityClasses[className](odataObject.CreatedBy);
      }
    }
  }
}

/**
 * CreatedBy entity class.
 */
export class CreatedByExtensionClass implements ICreatedBy {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public FullName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SystemUserId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public OwnerId: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.FullName = odataObject.FullName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SystemUserId = odataObject.SystemUserId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.OwnerId = odataObject.OwnerId;
  }
}

/**
 * CaseData entity class.
 */
export class CaseDataExtensionClass implements ICaseData {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ODataContext: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Value: ICustomerCase[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ODataContext = odataObject.ODataContext;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Value = undefined;
    if (odataObject.Value) {
      this.Value = [];
      for (var i = 0; i < odataObject.Value.length; i++) {
        if (odataObject.Value[i]) {
          if (odataObject.Value[i]["@odata.type"]) {
            var className: string = odataObject.Value[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Value[i] = new EntityClasses[className](
                odataObject.Value[i]
              );
            }
          } else {
            this.Value[i] = new CustomerCaseExtensionClass(
              odataObject.Value[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Value[i] = undefined;
        }
      }
    }
  }
}

/**
 * CustomerCase entity class.
 */
export class CustomerCaseExtensionClass implements ICustomerCase {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ODataETag: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public TicketNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CreatedOn: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public StatusCode: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public EmailAddress: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_DeliveryAddress: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_Country: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_ClaimCity: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_ClaimPostCode: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_ClaimStreet: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_NotifyMePerEmailWhenClaimIsUpdated: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_ConsumerName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_ConsumerContactDetails: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_CustomerConfirmedThePrivacyPolicy: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Description: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_OrderNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_OrderDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_SalesOrderLineNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_DateOfPurchase: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_SerialNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_HasThisProductBeenInAnAccident: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_TrackingNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_ShipmentTrackingId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_LoadId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_DeliveryNoteId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_InvoiceNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_InvoiceDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_InvoiceLineNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IncidentId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SubjectId: ISubjectid;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Customerid_account: ICustomerid_Account;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public PrimaryContactId: IPrimarycontactid;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Productid: IProductid;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ODataETag = odataObject.ODataETag;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.TicketNumber = odataObject.TicketNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CreatedOn = odataObject.CreatedOn;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.StatusCode = odataObject.StatusCode;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.EmailAddress = odataObject.EmailAddress;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_DeliveryAddress = odataObject.Br_DeliveryAddress;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_Country = odataObject.Br_Country;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_ClaimCity = odataObject.Br_ClaimCity;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_ClaimPostCode = odataObject.Br_ClaimPostCode;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_ClaimStreet = odataObject.Br_ClaimStreet;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_NotifyMePerEmailWhenClaimIsUpdated =
      odataObject.New_NotifyMePerEmailWhenClaimIsUpdated;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_ConsumerName = odataObject.New_ConsumerName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_ConsumerContactDetails = odataObject.New_ConsumerContactDetails;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_CustomerConfirmedThePrivacyPolicy =
      odataObject.New_CustomerConfirmedThePrivacyPolicy;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Description = odataObject.Description;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_OrderNumber = odataObject.New_OrderNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_OrderDate = odataObject.New_OrderDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_SalesOrderLineNumber = odataObject.New_SalesOrderLineNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_DateOfPurchase = odataObject.Br_DateOfPurchase;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_SerialNumber = odataObject.New_SerialNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_HasThisProductBeenInAnAccident =
      odataObject.Br_HasThisProductBeenInAnAccident;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_TrackingNumber = odataObject.Br_TrackingNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_ShipmentTrackingId = odataObject.New_ShipmentTrackingId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_LoadId = odataObject.New_LoadId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_DeliveryNoteId = odataObject.New_DeliveryNoteId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_InvoiceNumber = odataObject.New_InvoiceNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_InvoiceDate = odataObject.New_InvoiceDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_InvoiceLineNumber = odataObject.New_InvoiceLineNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IncidentId = odataObject.IncidentId;

    if (odataObject.SubjectId === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.SubjectId = undefined;
    } else if (odataObject.SubjectId["@odata.type"] == null) {
      this.SubjectId = new SubjectidExtensionClass(odataObject.SubjectId);
    } else {
      var className: string = odataObject.SubjectId["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.SubjectId = new EntityClasses[className](odataObject.SubjectId);
      }
    }

    if (odataObject.Customerid_account === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Customerid_account = undefined;
    } else if (odataObject.Customerid_account["@odata.type"] == null) {
      this.Customerid_account = new Customerid_AccountExtensionClass(
        odataObject.Customerid_account
      );
    } else {
      var className: string = odataObject.Customerid_account["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Customerid_account = new EntityClasses[className](
          odataObject.Customerid_account
        );
      }
    }

    if (odataObject.PrimaryContactId === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.PrimaryContactId = undefined;
    } else if (odataObject.PrimaryContactId["@odata.type"] == null) {
      this.PrimaryContactId = new PrimarycontactidExtensionClass(
        odataObject.PrimaryContactId
      );
    } else {
      var className: string = odataObject.PrimaryContactId["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.PrimaryContactId = new EntityClasses[className](
          odataObject.PrimaryContactId
        );
      }
    }

    if (odataObject.Productid === null || odataObject.Productid === undefined) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Productid = undefined;
    } else if (
      odataObject.Productid["@odata.type"] === null ||
      odataObject.Productid["@odata.type"] === undefined
    ) {
      this.Productid = new ProductidExtensionClass(odataObject.Productid);
    } else {
      var className: string = odataObject.Productid["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Productid = new EntityClasses[className](odataObject.Productid);
      }
    }
  }
}

/**
 * Productid entity class.
 */
export class ProductidExtensionClass implements IProductid {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Productnumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ProductId: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Productnumber = odataObject.Productnumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ProductId = odataObject.ProductId;
  }
}

/**
 * LatestNotes entity class.
 */
export class LatestNotesExtensionClass implements ILatestNotes {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ODataContext: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Value: ICaseNote[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ODataContext = odataObject.ODataContext;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Value = undefined;
    if (odataObject.Value) {
      this.Value = [];
      for (var i = 0; i < odataObject.Value.length; i++) {
        if (odataObject.Value[i]) {
          if (odataObject.Value[i]["@odata.type"]) {
            var className: string = odataObject.Value[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Value[i] = new EntityClasses[className](
                odataObject.Value[i]
              );
            }
          } else {
            this.Value[i] = new CaseNoteExtensionClass(odataObject.Value[i]);
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Value[i] = undefined;
        }
      }
    }
  }
}

/**
 * CaseWithAttachment entity class.
 */
export class CaseWithAttachmentExtensionClass implements ICaseWithAttachment {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public StatusCodes: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SubjectIdOdataBind: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CustomerIdAccountOdataBind: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public PrimaryContactIdOdataBind: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public EmailAddress: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_DeliveryAddress: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_ClaimCity: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_ClaimPostCode: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_ClaimStreet: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_Country: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_NotifyMePerEmailWhenClaimIsUpdated: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_ConsumerName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_ConsumerContactDetails: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_CustomerConfirmedThePrivacyPolicy: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Description: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_OrderNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_OrderDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_SalesOrderLineNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_DateOfPurchase: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ProductIdOdataBind: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_SerialNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_ShipmentTrackingId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_LoadId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_DeliveryNoteId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_InvoiceNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_InvoiceDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_InvoiceLineNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_HasThisProductBeenInAnAccident: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_TrackingNumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Br_ColorDescribtion: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public New_D365itemnumber: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Incident_Annotation: IAttachment[];

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CaseOriginCode: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.StatusCodes = odataObject.StatusCodes;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SubjectIdOdataBind = odataObject.SubjectIdOdataBind;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CustomerIdAccountOdataBind = odataObject.CustomerIdAccountOdataBind;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.PrimaryContactIdOdataBind = odataObject.PrimaryContactIdOdataBind;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.EmailAddress = odataObject.EmailAddress;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_DeliveryAddress = odataObject.Br_DeliveryAddress;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_ClaimCity = odataObject.Br_ClaimCity;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_ClaimPostCode = odataObject.Br_ClaimPostCode;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_ClaimStreet = odataObject.Br_ClaimStreet;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_Country = odataObject.Br_Country;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_NotifyMePerEmailWhenClaimIsUpdated =
      odataObject.New_NotifyMePerEmailWhenClaimIsUpdated;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_ConsumerName = odataObject.New_ConsumerName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_ConsumerContactDetails = odataObject.New_ConsumerContactDetails;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_CustomerConfirmedThePrivacyPolicy =
      odataObject.New_CustomerConfirmedThePrivacyPolicy;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Description = odataObject.Description;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_OrderNumber = odataObject.New_OrderNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_OrderDate = odataObject.New_OrderDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_SalesOrderLineNumber = odataObject.New_SalesOrderLineNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_DateOfPurchase = odataObject.Br_DateOfPurchase;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ProductIdOdataBind = odataObject.ProductIdOdataBind;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_SerialNumber = odataObject.New_SerialNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_ShipmentTrackingId = odataObject.New_ShipmentTrackingId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_LoadId = odataObject.New_LoadId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_DeliveryNoteId = odataObject.New_DeliveryNoteId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_InvoiceNumber = odataObject.New_InvoiceNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_InvoiceDate = odataObject.New_InvoiceDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_InvoiceLineNumber = odataObject.New_InvoiceLineNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_HasThisProductBeenInAnAccident =
      odataObject.Br_HasThisProductBeenInAnAccident;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_TrackingNumber = odataObject.Br_TrackingNumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Br_ColorDescribtion = odataObject.Br_ColorDescribtion;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.New_D365itemnumber = odataObject.New_D365itemnumber;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Incident_Annotation = undefined;
    if (odataObject.Incident_Annotation) {
      this.Incident_Annotation = [];
      for (var i = 0; i < odataObject.Incident_Annotation.length; i++) {
        if (odataObject.Incident_Annotation[i]) {
          if (odataObject.Incident_Annotation[i]["@odata.type"]) {
            var className: string =
              odataObject.Incident_Annotation[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Incident_Annotation[i] = new EntityClasses[className](
                odataObject.Incident_Annotation[i]
              );
            }
          } else {
            this.Incident_Annotation[i] = new AttachmentExtensionClass(
              odataObject.Incident_Annotation[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Incident_Annotation[i] = undefined;
        }
      }
    }

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CaseOriginCode = odataObject.CaseOriginCode;
  }
}

/**
 * UpdateCase entity class.
 */
export class UpdateCaseExtensionClass implements IUpdateCase {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public NoteText: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public FileName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsDocument: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public DocumentBody: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IncidentId: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.NoteText = odataObject.NoteText;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.FileName = odataObject.FileName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsDocument = odataObject.IsDocument;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.DocumentBody = odataObject.DocumentBody;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IncidentId = odataObject.IncidentId;
  }
}

/**
 * Attachment entity class.
 */
export class AttachmentExtensionClass implements IAttachment {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public NoteText: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public FileName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsDocument: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public DocumentBody: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.NoteText = odataObject.NoteText;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.FileName = odataObject.FileName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsDocument = odataObject.IsDocument;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.DocumentBody = odataObject.DocumentBody;
  }
}
